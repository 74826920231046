<template>
    <v-container class="container">
        <v-row v-if="mode && !['edit', 'create'].includes(mode)" class="edit-button-wrapper">
            <v-btn class="mx-2" dark medium color="#004cff" @click="onEdit">
                <v-icon dark>mdi-account-edit</v-icon>
            </v-btn>
        </v-row>
        <v-row>
            <v-col cols="6" md="3">
                <v-checkbox
                    v-model="member.vip"
                    label="Vip"
                />
            </v-col>
            <v-col cols="6" md="3">
                <v-checkbox
                    v-model="member.reliable"
                    label="Reliable"
                />
            </v-col>
            <v-col cols="6" md="3">
                <v-checkbox
                    v-model="member.exclusive"
                    label="Exclusive"
                />
            </v-col>
            <v-col cols="6" md="3">
                <v-checkbox
                    v-model="member.vodafone"
                    label="Vodafone"
                />
            </v-col>
            <v-col cols="6" md="3">
                <v-checkbox
                    v-model="member.energy"
                    label="Energy"
                />
            </v-col>
            <v-col cols="6" md="3">
                <v-checkbox
                    v-model="member.newsletter"
                    label="Newsletter"
                />
            </v-col>
            <v-col cols="8" />
            <v-col cols="6">
                <v-autocomplete
                    v-model="member.team"
                    :items="teams"
                    :item-text="getTeamInfo"
                    menu-props="auto"
                    label="Select Team"
                    hide-details
                    prepend-icon="mdi-account-group"
                    single-line
                    :clearable="!readOnly"
                    return-object
                    deletable-chips
                    chips
                />
            </v-col>
            <v-col cols="6">
                <v-autocomplete
                    v-model="member.dealer"
                    :items="dealers"
                    :item-text="getDealerInfo"
                    menu-props="auto"
                    label="Select Dealer"
                    hide-details
                    prepend-icon="mdi-account-group"
                    single-line
                    :clearable="!readOnly"
                    return-object
                    deletable-chips
                    chips
                />
            </v-col>
            <v-col cols="6">
                <v-text-field
                    v-model="member.vipGroup"
                    label="Vip Group"
                    :clearable="clearable"
                />
            </v-col>
            <v-col cols="6">
                <v-text-field
                    v-model="member.number"
                    :rules="rules"
                    label="Number"
                    type="number"
                    :clearable="clearable"
                />
            </v-col>
            <v-col cols="6">
                <v-text-field
                    v-model="member.firstName"
                    :rules="rules"
                    label="First Name"
                    :clearable="clearable"
                />
            </v-col>
            <v-col cols="6">
                <v-text-field
                    v-model="member.lastName"
                    :rules="rules"
                    label="Last Name"
                    :clearable="clearable"
                />
            </v-col>
            <v-col cols="6">
                <v-text-field
                    v-model="member.fatherName"
                    label="Father Name"
                    :clearable="clearable"
                />
            </v-col>
            <v-col cols="6">
                <v-text-field
                    v-model="member.profession"
                    label="Profession"
                    :clearable="clearable"
                />
            </v-col>
            <v-col cols="6">
                <v-text-field
                    v-model="member.phone"
                    label="Phone"
                    :clearable="clearable"
                />
            </v-col>
            <v-col cols="6">
                <v-text-field
                    v-model="member.email"
                    label="Email"
                    :clearable="clearable"
                />
            </v-col>
            <v-col cols="6">
                <v-text-field
                    v-model="member.address"
                    label="Address"
                    :clearable="clearable"
                />
            </v-col>
            <v-col cols="6">
                <v-text-field
                    v-model="member.postalCode"
                    label="Postal Code"
                    :clearable="clearable"
                />
            </v-col>
            <v-col cols="6">
                <v-text-field
                    v-model="member.recommendation"
                    label="Recommendation"
                    :clearable="clearable"
                />
            </v-col>
            <v-col v-if="!['edit', 'create'].includes(mode)" cols="6">
                <v-autocomplete
                    v-model="cards"
                    :items="cards"
                    name="number"
                    :item-text="'number'"
                    attach
                    chips
                    label="Cards"
                    multiple
                    deletable-chips
                    readonly
                />
            </v-col>
            <v-col cols="6">
                <v-menu
                    :close-on-content-click="true"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="member.birthDate"
                            label="Choose your birth date"
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            :readonly="readOnly"
                            clearable
                            v-on="on"
                        />
                    </template>
                    <v-date-picker
                        v-model="member.birthDate"
                        :model-config="modelConfig"
                        class="datepicker"
                        :readonly="readOnly"
                    />
                </v-menu>
            </v-col>
            <v-col cols="6">
                <v-menu
                    :close-on-content-click="true"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="member.subscriptionDate"
                            label="Choose your subscription date"
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            :readonly="readOnly"
                            clearable
                            v-on="on"
                        />
                    </template>
                    <v-date-picker
                        v-model="member.subscriptionDate"
                        :model-config="modelConfig"
                        class="datepicker"
                        :readonly="readOnly"
                    />
                </v-menu>
            </v-col>
            <v-col cols="6">
                <v-file-input
                    v-if="mode !== 'show'"
                    v-model="newImage"
                    label="Image input"
                    filled
                    show-size
                    prepend-icon="mdi-camera"
                    @change="createNewImagePreview"
                />
            </v-col>
            <div class="image-input-wrapper">
                <div v-if="previewImage && !previewImage.shouldDelete" class="image-input" :class="{'green-border': !previewImage.id}">
                    <div v-if="mode === 'edit'" class="delete-btn">
                        <v-btn class="mx-2 white--text" small color="red" @click="onImageDelete">
                            X
                        </v-btn>
                    </div>
                    <v-img v-if="previewImage.url" class="img-preview" :src="previewImage.url" />
                    <v-text-field
                        v-model="previewImage.description"
                        label="Image Description"
                        :clearable="clearable"
                    />
                </div>
            </div>
        </v-row>
        <v-row align="center">
            <v-col cols="12">
                <v-expansion-panels
                    v-for="(card, index) of member.cards"
                    :key="index"
                    class="my-5"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header class="card-title px-4 mt-1">
                            {{ 'Card - ' + card.type + ' - ' + card.number }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <card-form
                                :index="index"
                                :member-card="card"
                                :member="member"
                                :force-mode="Object.keys(card).length > 0 ? 'edit' : 'create'"
                                @setAlert="setAlert"
                            />
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <span class="mt-3 mx-3"> Remove Member Card</span>
                    <v-menu
                        :close-on-content-click="true"
                        :nudge-width="200"
                        offset-x
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                small
                                color="black"
                                class="mt-3"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon color="white">
                                    mdi-trash-can
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-card height="80px" class="py-2">
                            <div class="text">
                                Are you sure?
                            </div>
                            <v-card-actions class="choices">
                                <v-btn class="mx-2" dark small color="#027a00" @click="removeMemberCard(index)">
                                    Yes
                                </v-btn>
                                <v-btn
                                    class="mx-2"
                                    dark
                                    small
                                    color="#ff0000"
                                >
                                    No
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-menu>
                </v-expansion-panels>
            </v-col>
        </v-row>
        <v-row v-if="addCards">
            <v-col cols="12">
                <span> Add Member Card</span>
                <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="black"
                    @click="addMemberCard()"
                >
                    <v-icon dark>
                        mdi-plus
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <div v-if="mode !== 'show'" class="submit-button-wrapper">
            <v-btn class="mx-2 white--text" large :disabled="shouldDisableSubmit()" color="#004cff" @click="onSubmit">
                Submit
            </v-btn>
            <v-btn class="mx-2 white--text" large color="black" @click="$router.go(-1)">
                Back
            </v-btn>
        </div>
        <alert :show-alert="showAlert" :message="message" :type="type" />
    </v-container>
</template>

<script>
    import moment from 'moment'
    import store from '@/store'
    import imageService from '@/services/image-service'
    import teamService from '@/services/team-service'
    import dealerService from '@/services/dealer-service'
    import memberService from '@/services/member-service'
    import CardForm from '@/forms/CardForm'
    import Alert from '@/components/Alert'
    import cardService from '@/services/card-service'

    export default {
        name: 'MemberForm',
        components: { CardForm, Alert },
        data: () => {
            return {
                showAlert: false,
                mode: null,
                apiUrl: process.env.VUE_APP_API_HOST_DOMAIN,
                readOnly: false,
                clearable: true,
                available: null,
                teams: [],
                member: {
                    firstName: '',
                    lastName: '',
                    profession: '',
                    fatherName: '',
                    birthDate: null,
                    subscriptionDate: '',
                    phone: '',
                    email: '',
                    address: '',
                    recommendation: '',
                    vipGroup: '',
                    vip: false,
                    reliable: false,
                    vodafone: false,
                    energy: false,
                    newsletter: false,
                    approved: true,
                    cards: [],
                    dealer: null
                },
                cards: [],
                memberId: null,
                modelConfig: {
                    type: 'String',
                    mask: 'yyyy-MM-DD'
                },
                newImage: null,
                previewImage: null,
                rules: [
                    v => !!v || 'Field is required'
                ],
                addCards: false,
                type: null,
                message: null,
                dealers: []
            }
        },
        computed: {
            dealerId() {
                return store.getters.currentUser && store.getters.currentUser.role === 'dealer' && store.getters.currentUser.dealerId
            }
        },
        created() {
            this.mode = this.$route.params.mode
            this.fetchAllTeams()
            this.fetchAllDealers()
            if (this.mode === 'create') {
                return
            } else if (this.mode === 'show') {
                this.readOnly = true
                this.clearable = false
            } else {
                this.addCards = true
            }
            this.memberId = this.$route.params.memberId
            if (this.memberId) {
                memberService.getMemberById(this.memberId).then(resp => {
                    this.member = resp.data.data.member
                    this.previewImage = resp.data.data.member.image
                    if (this.previewImage) {
                        this.previewImage.url = this.apiUrl + '/uploads/images/' + this.previewImage.imageName
                    }
                    this.member.birthDate = this.formatDate(resp.data.data.member.birthDate, 'yyyy-MM-DD')
                    this.member.subscriptionDate = this.formatDate(resp.data.data.member.subscriptionDate, 'yyyy-MM-DD')
                    this.cards = resp.data.data.member.cards
                })
            }
        },
        methods: {
            setAlert(message, type) {
                this.showAlert = true
                this.message = message
                this.type = type
                setTimeout(() => { this.showAlert = false }, 7000)
            },
            updateCard(variant, index) {
                this.member.cards[index] = variant
            },
            addMemberCard() {
                this.member.cards.push({})
            },
            removeMemberCard(index) {
                cardService.deleteCardById(this.member.cards[index].id).then(() => {
                    this.member.cards.splice(index, 1)
                    this.setAlert('Card deletion was successfull', 'success')
                }).catch((error) => {
                    this.setAlert(error.response, 'error')
                })
            },
            fetchAllDealers() {
                dealerService.getAllDealers().then(resp => {
                    this.dealers = resp.data.data
                })
            },
            fetchAllTeams() {
                teamService.getTeamsByFilter(null, {}).then(resp => {
                    this.teams = resp.data.data
                })
            },
            shouldDisableSubmit() {
                return !this.member.firstName || !this.member.lastName
            },
            onEdit() {
                this.$router.push(`/members/${this.memberId}/edit?page=${this.$route.query.page}`)
            },
            async onSubmit() {
                this.prepareIfDealerExists()
                this.deleteUnecessaryFields()
                if (this.mode === 'edit') {
                    this.editMember()
                } else if (this.mode === 'create') {
                    this.createMember()
                }
                this.deleteMemberImage()
                this.editImageDescriptions()
            },
            prepareIfDealerExists() {
                const dealerId = this.dealerId
                if (dealerId) {
                    this.member.dealer = dealerId
                    this.member.approved = false
                } else if (this.member.dealer) {
                    this.member.dealer = this.member.dealer.id
                } else {
                    this.member.dealer = null
                }
            },
            editMember() {
                memberService.editMemberById(this.memberId, this.member).then(resp => {
                    this.uploadImage()
                    this.addCards = true
                    this.setAlert('Member edit was successfull', 'success')
                }).catch((error) => {
                    this.setAlert(error.response, 'error')
                })

                // this.$router.push(`/members?page=${this.$route.query.page}`)
            },
            createMember() {
                memberService.createMember(this.member).then(response => {
                    if (response.data.id) {
                        this.memberId = response.data.id
                        this.member.id = response.data.id
                        this.uploadImage()
                    }
                    this.addCards = true
                    this.setAlert('Member creation was successfull', 'success')
                }).catch((error) => {
                    this.setAlert(error.response, 'error')
                })
                // this.$router.push(`/members?page=${this.$route.query.page}`)
            },
            deleteMemberImage() {
                if (this.previewImage && this.previewImage.id && this.previewImage.shouldDelete) {
                    imageService.deleteImageById(this.previewImage.id)
                    this.previewImage = null
                }
            },
            editImageDescriptions() {
                if (this.previewImage && this.previewImage.id) {
                    let formData = new FormData()
                    formData.append('memberId', this.memberId)
                    if (this.previewImage.description) {
                        formData.append('description', this.previewImage.description)
                    }
                    imageService.editImageById(this.previewImage.id, formData)
                }
            },
            uploadImage() {
                if (!this.newImage || (this.newImage && this.newImage.id)) {
                } else {
                    let formData = new FormData()
                    formData.append('file', this.newImage)
                    formData.append('memberId', this.memberId)
                    if (this.newImage.description) {
                        formData.append('description', this.newImage.description)
                    }
                    imageService.uploadNewImage(formData)
                }
            },
            onImageDelete() {
                if (this.previewImage.id) {
                    this.previewImage.shouldDelete = true
                    this.previewImage.url = null
                    this.previewImage = { ...this.previewImage }
                } else {
                    this.newImage = null
                }
            },
            createNewImagePreview() {
                this.previewImage = this.newImage
                this.previewImage.url = URL.createObjectURL(this.newImage)
                this.previewImage.description = null
            },
            deleteUnecessaryFields() {
                delete this.member.id
                delete this.member.image
                if (this.member.team) {
                    this.member.team = this.member.team.id
                }
            },
            formatDate(date, format) {
                if (!date) {
                    return null
                }
                return moment(date).format(format)
            },
            getTeamInfo(team) {
                return team.title
            },
            getDealerInfo(dealer) {
                return dealer.firstName + ' ' + dealer.lastName
            }
        }
    }
</script>

<style scoped>

.choices {
    display: flex;
    justify-content: space-evenly;
}

.text {
    text-align: center;
}
.edit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.submit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2vh;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.img-preview {
    max-width: 70%;
    margin: 0 auto;
}

.image-input {
    padding: 20px;
    width: 100%;
    border-style: groove;
    border: #D3D3D3 solid 2px;
    border-radius: 15px 50px;
    margin-top: 1vh;
}

.green-border {
    border-color: green;
}

.delete-btn {
    display: flex;
    justify-content: flex-end;
}
</style>
